// import { TextDecoder, TextEncoder } from 'util';
(window as any).global = window;
(window as any).process = { env: { DEBUG: undefined } };
// remove console group method
console.group = () => {};
console.debug = () => {};
//https://github.com/angular/components/issues/24979
document.addEventListener('mousemove', () => {}, {
  passive: false,
  capture: true,
});
// global.TextDecoder = TextDecoder as any;
// global.TextEncoder = TextEncoder as any;
